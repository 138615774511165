<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Schritt 3</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-row class="sideMargins topMargins">
        <ion-col size="12">
          <ion-button size="small" style="margin-left: 16px;" @click="goBackOneStep"><ion-icon :ios="backIconIos" :md="backIconMd"></ion-icon> Zurück</ion-button>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-grid>
              <ion-row>
                <ion-col size="3">
                  <ion-toggle v-model="isGoodCause" style="margin-bottom: 10px;"></ion-toggle>
                </ion-col>
                <ion-col size="9">
                  <ion-label class="ion-text-wrap">Soll der Erlös gespendet werden?</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-col>
        <ion-col size="12" v-if="isGoodCause">
          <ion-item>
            <ion-label position="stacked">Spendenempfänger</ion-label>
            <ion-select placeholder="auswählen" v-model="donationRecipientId">
              <ion-select-option v-for="d in donationRecipients" :key="d.id" :value="d.uuid">{{ d.name }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col size="12" v-if="!errorWhileLoading">
          <ion-button size="large" class="topMargins sideMargins" @click="nextStep()" expand="block" color="primary">Nächster Schritt</ion-button>
        </ion-col>
      </ion-row>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonItem, IonLabel, IonButton, toastController, IonToggle, IonSelect, IonSelectOption, IonGrid, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { arrowBackOutline, arrowBackSharp } from 'ionicons/icons';

export default defineComponent({
  name: 'InseratCreateStep3',
  data () {
    return {
      dataFromPreviousStepIsCollected: false,
      isGoodCause: false,
      donationRecipient: "",
      donationRecipientId: "",
      donationRecipients: null,
      totalRecipients: 0,
      errorWhileLoading: false,
      backIconIos: arrowBackOutline,
      backIconMd: arrowBackSharp
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonButton,
    IonToggle,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonIcon
  },
  created() {

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' })
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' })
    }

    // check if data is collected from previous steps
    if (!localStorage.inseratType){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratTitle){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratDescription){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCategoryId){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratPrice){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCurrency){
      this.$router.push({ path: '/inserat/create' })
    }

    // check step
    if (localStorage.inseratStep !== 3){
      // redirect to right step
      if (localStorage.inseratStep == 0){
        this.$router.push({ path: '/inserat/create' });
      } else {
        this.$router.push({ path: '/inserat/create/step-' + localStorage.inseratStep });
      }
    }

  },
  mounted() {

    // get list of donation recipients
    axios.get(process.env.VUE_APP_API_URL + '/donation_recipients')
        .then(response => {
          this.donationRecipients = response.data["hydra:member"];
          this.totalRecipients = response.data["hydra:totalItems"];
        })
        .finally(() => {
          if (this.totalRecipients == 0){
            this.openToast("Etwas ist schiefgelaufen. Probiere es später nochmal.", 10000);
            this.errorWhileLoading = true;
          }
        });

  },
  methods: {
    goBackOneStep() {
      localStorage.inseratStep = 2;
      this.$router.push({ path: '/inserat/create/step-2' })
    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    nextStep() {
        let errors = 0;

        // if isGoodCause is Enabled the User has to choose a Donation Recipient
        if (this.isGoodCause && !this.donationRecipientId){
          errors++;
            this.openToast("Du musst einen Spendenempfänger auswählen.", 2000);
        }

        if (errors === 0){

          // save in localStorage
          localStorage.inseratIsGoodCause = this.isGoodCause;
          localStorage.inseratDonationRecipient = this.donationRecipient;
          localStorage.inseratDonationRecipientId = this.donationRecipientId;

          // go to next step
          localStorage.inseratStep = 4;
          this.$router.push({ path: '/inserat/create/step-4' })

        }


    }
  }
});

</script>

<style scoped>

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 10%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.imageCircleDenied {
  border: 2px solid #c61d53;
}

.imageCircleAccepted {
  border: 2px solid #1dc620;
}

.rounded-image {
  border-radius: 50%;
}

</style>