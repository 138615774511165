
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonItem, IonLabel, IonButton, toastController, IonToggle, IonSelect, IonSelectOption, IonGrid, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { arrowBackOutline, arrowBackSharp } from 'ionicons/icons';

export default defineComponent({
  name: 'InseratCreateStep3',
  data () {
    return {
      dataFromPreviousStepIsCollected: false,
      isGoodCause: false,
      donationRecipient: "",
      donationRecipientId: "",
      donationRecipients: null,
      totalRecipients: 0,
      errorWhileLoading: false,
      backIconIos: arrowBackOutline,
      backIconMd: arrowBackSharp
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonButton,
    IonToggle,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonIcon
  },
  created() {

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' })
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' })
    }

    // check if data is collected from previous steps
    if (!localStorage.inseratType){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratTitle){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratDescription){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCategoryId){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratPrice){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCurrency){
      this.$router.push({ path: '/inserat/create' })
    }

    // check step
    if (localStorage.inseratStep !== 3){
      // redirect to right step
      if (localStorage.inseratStep == 0){
        this.$router.push({ path: '/inserat/create' });
      } else {
        this.$router.push({ path: '/inserat/create/step-' + localStorage.inseratStep });
      }
    }

  },
  mounted() {

    // get list of donation recipients
    axios.get(process.env.VUE_APP_API_URL + '/donation_recipients')
        .then(response => {
          this.donationRecipients = response.data["hydra:member"];
          this.totalRecipients = response.data["hydra:totalItems"];
        })
        .finally(() => {
          if (this.totalRecipients == 0){
            this.openToast("Etwas ist schiefgelaufen. Probiere es später nochmal.", 10000);
            this.errorWhileLoading = true;
          }
        });

  },
  methods: {
    goBackOneStep() {
      localStorage.inseratStep = 2;
      this.$router.push({ path: '/inserat/create/step-2' })
    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    nextStep() {
        let errors = 0;

        // if isGoodCause is Enabled the User has to choose a Donation Recipient
        if (this.isGoodCause && !this.donationRecipientId){
          errors++;
            this.openToast("Du musst einen Spendenempfänger auswählen.", 2000);
        }

        if (errors === 0){

          // save in localStorage
          localStorage.inseratIsGoodCause = this.isGoodCause;
          localStorage.inseratDonationRecipient = this.donationRecipient;
          localStorage.inseratDonationRecipientId = this.donationRecipientId;

          // go to next step
          localStorage.inseratStep = 4;
          this.$router.push({ path: '/inserat/create/step-4' })

        }


    }
  }
});

